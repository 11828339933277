import { TECH } from "content/tech-stack";
import { Link } from "components/link";

export const CV = {
  name: "Darshan Parbadiya",
  title: "Fullstack Engineer",
  primary_stack: [TECH.typescript, TECH.react, TECH.nodejs, TECH.tailwindcss, TECH.mysql],
  email: "parbadid@mcmaster.ca",
  mobile: {
    href: `tel:+4376777727`,
    number: "+1(437)-677-7727",
  },

  experience: [
    {
      dateFrom: "2024/05/01",
      dateTo: "Present",
      city: "Ontario",
      country: "Canada",
      company: "Portland Investment Counsel",
      title: "Business Systems Analyst, Co-op",
      type: ["business analysis", "software development", "data analysis", "relevant"],
      description: "",
      responsibilities: [
        {
          content:
            "Contributed to the design, development, and deployment of innovative, enterprise-grade web applications using ReactJS and a wide range of backend technologies. ",
          type: ["data analysis", "business intelligence", "relevant"],
        },
        {
          content:
            "Managed AWS resources, such as deployment pipelines, Kubernetes instances, and Docker containers, using Terraform. Developed automated tests using Jest, RTL and Selenium, improving application stability and reducing errors. ",
          type: ["data analysis", "business intelligence", "relevant"],
        },
        // {
        //   content:
        //     "Employed test-driven development (TDD) practices to build reliable, bug-free solutions, and maintained automated test suites for consistent regression testing.",
        //   type: ["software development", "quality assurance", "relevant"],
        // },
      ],
    },
    // {
    //   dateFrom: "2024/01/01",
    //   dateTo: "2024/05/01",
    //   city: "Ontario",
    //   country: "Canada",
    //   company: "Direct Cell",
    //   title: "Technical Specialist, Part Time",
    //   description: "",
    //   type: ["technical support", "hardware/software repair", "customer service", "relevant"],
    //   responsibilities: [
    //     {
    //       content:
    //         "Diagnosed and repaired a wide range of electronic devices, including phones, tablets, laptops, and gaming consoles, addressing both hardware and software issues.",
    //       type: ["hardware repair", "software troubleshooting", "relevant"],
    //     },
    //     {
    //       content: "Built strong client relationships through excellent service and support.",
    //       type: ["customer service", "client relations", "relevant"],
    //     },
    //   ],
    // },
    {
      dateFrom: "2022/03/01",
      dateTo: "2023/07/01",
      city: "Surat",
      country: "India",
      company: "Epsilon Tech",
      title: "Application Developer",
      description: "",
      type: ["software development", "front-end development", "web / tech dev", "relevant"],
      responsibilities: [
        {
          content:
            "Crafted dynamic, full-stack web applications with a robust Node.js backend, seamlessly paired with an intuitive, visually striking ReactJS frontend for an exceptional user experience. .",
          type: ["web development", "user experience", "relevant"],
        },
        {
          content:
            "Created and maintained comprehensive documentation for code, processes, and applications, contributing to the clarity and transparency of project workflows.",
          type: ["software development", "user experience", "relevant"],
        },
      ],
    },
  ],
  capabilities: {
    languages: [
      {
        name: "English - bilingual",
        Icon: null,
      },
      {
        name: "Gujarati - native",
        Icon: null,
      },
    ],
    programmingLanguages: [
      TECH.typescript,
      TECH.javascript,
      TECH.html_5,
      TECH.css_3,
      TECH.sass,
      TECH.graphql,
      TECH.markdown,
      TECH.liquid,
      TECH.sql,
      TECH.ruby,
      TECH.php,
    ],
    librariesFrameworks: [
      TECH.react,
      TECH.tailwindcss,
      TECH.nextjs,
      TECH.trpc,
      TECH.prisma,
      TECH.react_query,
      TECH.remix,
      TECH.jest,
      TECH.turborepo,
      TECH.nodejs,
      TECH.express,
      TECH.webpack,
      TECH.framer,
      TECH.redis,
    ],
    serviceProviders: [
      TECH.vercel,
      TECH.aws,
      TECH.firebase,
      TECH.netlify,
      TECH.planetscale,
      TECH.github,
    ],
    marketing: [TECH.vercel, TECH.aws, TECH.firebase, TECH.netlify, TECH.planetscale, TECH.github],
    dataProviders: [TECH.shopify, TECH.vend, TECH.erply, TECH.dear, TECH.micros, TECH.wordpress],
    tools: [
      TECH.intellij_idea,
      TECH.git,
      TECH.yarn,
      TECH.npm,
      TECH.illustrator,
      TECH.photoshop,
      TECH.figma,
      TECH.postman,
      TECH.ftp,
      TECH.office,
      TECH.dev_tools,
    ],
  },
  education: [
    {
      dateFrom: "2023/09/01",
      dateTo: "2025/09/01",
      city: "Hamilton",
      country: "Canada",
      company: "McMaster University",
      title: "M.Eng, Systems And Technology (Co-op Program)",
      type: ["education", "relevant", "deep learning", "AI/ML"],
      description: "",
      responsibilities: [
        {
          content:
            "Relevant Courses: Deep Learning, AI ML fundamentals, AI ML classification models, Multivariate statistics.",
          type: ["software development", "relevant"],
        },
      ],
    },
    {
      dateFrom: "2017/06/01",
      dateTo: "2021/05/01",
      city: "India",
      country: "India",
      company: "Gujarat Technological University",
      title: "B.Tech, Computer Engineering; CGPA: 9.12/10",
      type: ["education", "relevant", "computer engineering"],
      description: "",
      responsibilities: [
        {
          content:
            "Relevant Courses: Compiler design, DBMS, Theory of Computation, Computer Networks",
          type: ["software development", "relevant"],
        },
      ],
    },
  ],
};